import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';

const Report = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch('https://mxk0258.uta.cloud/api/data')
      .then(response => response.json())
      .then(data => setData(data));
  }, []);
  
  const exportExcel = () => {
    const wb = XLSX.utils.book_new();
    console.log(data)
    // Add sheets to workbook
    const advertisements = XLSX.utils.json_to_sheet(data.advertisements);
    const events = XLSX.utils.json_to_sheet(data.events);
    const memberships = XLSX.utils.json_to_sheet(data.memberships);
    
    XLSX.utils.book_append_sheet(wb, advertisements, "Advertisements");
    XLSX.utils.book_append_sheet(wb, events, "Events");
    XLSX.utils.book_append_sheet(wb, memberships, "Memberships");
    
    // Export workbook
    XLSX.writeFile(wb, "Report.xlsx");
  }
  
  return (
    <button onClick={exportExcel}>Download Report</button>
  );
};

export default Report;
