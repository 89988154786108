import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Table, Row, Col } from 'antd';
import ContactList from './contactsInfo';
import BarChart from './barchart';

const DataDashboard = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await fetch('https://mxk0258.uta.cloud/api/data');
    const data = await response.json();
    setData(data);
  };

  const chartData = [
    { name: 'Jan', advertisements: 4000, events: 2400 },
    { name: 'Feb', advertisements: 3000, events: 1398 },
    { name: 'Mar', advertisements: 2000, events: 9800 },
    { name: 'Apr', advertisements: 2780, events: 3908 },
    { name: 'May', advertisements: 1890, events: 4800 },
    { name: 'Jun', advertisements: 2390, events: 3800 },
    { name: 'Jul', advertisements: 3490, events: 4300 },
    { name: 'Aug', advertisements: 5000, events: 3000 },
    { name: 'Sep', advertisements: 4500, events: 1000 },
    { name: 'Oct', advertisements: 3000, events: 6000 },
    { name: 'Nov', advertisements: 2400, events: 2400 },
    { name: 'Dec', advertisements: 1000, events: 2000 },
  ];

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Event Name', dataIndex: 'name', key: 'name' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    { title: 'Start Time', dataIndex: 'start_time', key: 'start_time' },
    { title: 'End Time', dataIndex: 'end_time', key: 'end_time' },
  ];

  return (
    <>
    <Row gutter={[16, 16]}>
      <Col xs={24} md={12}>
        <h3>Chart</h3>
        <LineChart width={550} height={300} data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="advertisements" stroke="#8884d8" />
          <Line type="monotone" dataKey="events" stroke="#82ca9d" />
        </LineChart>
      </Col>
      <Col xs={24} md={12}>
        <h3>Events</h3>
        <Table dataSource={data.events} columns={columns} />
      </Col>
    </Row>
    <Row>
      <Col xs={24} md={12}>
        <h3>Contact List Who contacted through contact Form</h3>
        <ContactList />
      </Col>
      <Col xs={24} md={12}>
        <h3>Bar chart</h3>
        <BarChart/>
      </Col>
     
    </Row>
    </>
  );
};

export default DataDashboard;
