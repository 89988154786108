import React from "react";
import { Tabs } from "antd";
import AdminHeader from './admin-header'
import VehicleDashboard from "./vehicles";
import Memberships from "./memberships";
import Events from "./events";
import Pool from "./pool";

const { TabPane } = Tabs;

const Vehicles = () => {
  return (
    <div>
      <h2>Vehicles Details</h2>
      <VehicleDashboard/>
    </div>
  );
};

// const Memberships = () => {
//   return (
//     <div>
//       <h2>Memberships</h2>
//       <Memberships/>
//     </div>
//   );
// };

// const Events = () => {
//   return (
//     <div>
//       <h2>Events</h2>
//       <Events/>
//     </div>
//   );
// };

const AccessTimings = () => {
  return (
    <div>
      <h2>Access Timings</h2>
      {/* Add code here to display access timings */}
    </div>
  );
};

const Resident = () => {
  return (
    <div>
 <div>
      <AdminHeader headerContent ={<section className="container hero row">
            <div className="content">
                <h1>Welcome !!! Resident</h1>
            </div>
        </section>}/>

    </div>
      <Tabs defaultActiveKey="1" vertical>
        <TabPane tab="Vehicles" key="1">
          <Vehicles />
        </TabPane>
        <TabPane tab="Memberships" key="2">
          <Memberships />
        </TabPane>
        <TabPane tab="Events" key="3">
          <Events />
        </TabPane>
        <TabPane tab="Access Timings" key="4">
          {/* <AccessTimings /> */}
          <Pool/>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Resident;