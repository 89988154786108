import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'antd';
import axios from '../axiosConfig';

const Pool = () => {
    const user = JSON.parse(sessionStorage.getItem('user'))
  const [poolTimings, setPoolTimings] = useState([]);
  const [advertisements, setAdvertisements] = useState([]);

  useEffect(() => {
    getPoolTimings();
    getAdvertisements();
  }, []);

  const getPoolTimings = async () => {
    try {
      const response = await axios.get(`/api/pool-managers/${user.id}/pool-timings`);
      setPoolTimings(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAdvertisements = async () => {
    try {
      const response = await axios.get(`/api/pool-managers/${user.id}/advertisements/all`);
      setAdvertisements(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        {poolTimings.map((poolTiming) => (
          <Col key={poolTiming.id} xs={24} sm={12} md={8} lg={6}>
            <Card title={poolTiming.day_of_week}>
              <p>Start time: {poolTiming.start_time}</p>
              <p>End time: {poolTiming.end_time}</p>
            </Card>
          </Col>
        ))}
      </Row>

      <Row gutter={[16, 16]}>
        {advertisements.map((advertisement) => (
          <Col key={advertisement.id} xs={24} sm={12} md={8} lg={6}>
            <Card>
              <Card.Meta title={advertisement.event_name} description={advertisement.event_description} />
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Pool;
