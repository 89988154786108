import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input } from 'antd';
import axios from 'axios';

const VisitorInfoPage = () => {
  const [visitorData, setVisitorData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const userItem = JSON.parse(sessionStorage.getItem('user'));
  const apiBaseUrl = 'https://mxk0258.uta.cloud/api';
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    const fetchVisitorData = async () => {
      const visitorId = JSON.parse(sessionStorage.getItem('user'));
      const response = await axios.get(`${apiBaseUrl}/visitors/${visitorId.id}`);
      if (response.data.visitor) {
        setVisitorData([response.data.visitor]);
      }
    };
    fetchVisitorData();
  }, []);

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile_number',
      key: 'mobile_number',
    },
    {
      title: 'Driving License Number',
      dataIndex: 'dl_number',
      key: 'dl_number',
    },
    {
      title: 'Car Number',
      dataIndex: 'car_number',
      key: 'car_number',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Button type="primary" onClick={() => onEdit(record)}>
            Edit
          </Button>
          <Button type="danger" onClick={() => onDelete(record)}>
            Delete
          </Button>
        </span>
      ),
    },
  ];

  const onAdd = () => {
    setIsModalVisible(true);
  };

  const onEdit = (record) => {
    setIsEditing(true)
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const onDelete = async (record) => {
    const response = await axios.delete(`${apiBaseUrl}/visitors/${userItem.id}`);
    if (response.data.message) {
      setVisitorData([]);
    }
  };

  const onFinish = async (values) => {
    if(isEditing){
        const visitorId = JSON.parse(sessionStorage.getItem('user'));
        console.log(visitorId)
        const { full_name, email, mobile_number, dl_number, car_number } = values;
        const response = await axios.put(`${apiBaseUrl}/visitors/${visitorId.id}`, {
          user_id: visitorId.id,
          full_name: full_name,
          email: email,
          mobile_number: mobile_number,
          dl_number: dl_number,
          car_number: car_number,
        });
        if (response.data.message) {
          form.resetFields();
          setIsModalVisible(false);
          const visitorResponse = await axios.get(`${apiBaseUrl}/visitors/${visitorId.id}`);
          if (visitorResponse.data.visitor) {
            setVisitorData([visitorResponse.data.visitor]);
          }
        }
        setIsEditing(false) 
    }
    const visitorId = JSON.parse(sessionStorage.getItem('user'));
    console.log(visitorId)
    const { full_name, email, mobile_number, dl_number, car_number } = values;
    const response = await axios.post(`${apiBaseUrl}/visitors`, {
      user_id: visitorId.id,
      full_name: full_name,
      email: email,
      mobile_number: mobile_number,
      dl_number: dl_number,
      car_number: car_number,
    });
    if (response.data.message) {
      form.resetFields();
      setIsModalVisible(false);
      const visitorResponse = await axios.get(`${apiBaseUrl}/visitors/${visitorId.id}`);
      if (visitorResponse.data.visitor) {
        setVisitorData([visitorResponse.data.visitor]);
      }
    }
  };

  return (
    <div>
        {visitorData.length > 0 ? 'Below yo can see your Information' : <Button type="primary" onClick={onAdd}>
        Add
      </Button>}
      
      <Table dataSource={visitorData} columns={columns} />
      <Modal
        title="Visitor Information"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={onFinish}>
          <Form.Item name="full_name" label="Full Name">
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input />
          </Form.Item>
          <Form.Item name="mobile_number" label="Mobile Number">
            <Input />
          </Form.Item>
          <Form.Item name="dl_number" label="Driving License Number">
            <Input />
          </Form.Item>
          <Form.Item name="car_number" label="Car Number">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button type="danger" onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default VisitorInfoPage;