import React, { useState } from 'react';
import { Tabs } from 'antd';
import AdminHeader from './admin-header'
import PoolTimings from './pooltimings';
import ResidentsAccess from './resaccess';


import VisitorsAccess from './visitoraccess';
import Advertisements from './advertisement';

const { TabPane } = Tabs;

const PoolManager = () => {
  const [activeTab, setActiveTab] = useState('1');

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <>
    <AdminHeader headerContent ={<section className="container hero row">
            <div className="content">
                <h1>Welcome !!! Pool Manager</h1>
            </div>
        </section>}/>
    <Tabs activeKey={activeTab} onChange={handleTabChange}>
      <TabPane tab="Pool Timings" key="1">
        <PoolTimings />
      </TabPane>
      <TabPane tab="Residents Access" key="2">
        <div>
            <ResidentsAccess />
        </div>
      </TabPane>
      <TabPane tab="Visitors Access" key="3">
        <VisitorsAccess />
      </TabPane>
      <TabPane tab="Advertisements" key="4">
        <Advertisements />
      </TabPane>
    </Tabs>
    </>
  );
};

export default PoolManager;
