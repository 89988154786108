import React, { useState } from 'react';
import { Tabs } from 'antd';
import AdminHeader from './admin-header'
import SecurityPersonnel from './securitypersonal';
import ResidentsAndVisitorsTable from './visitorstable';


const { TabPane } = Tabs;

const SecurityManager = () => {
  const [activeTab, setActiveTab] = useState('1');

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <>
    <AdminHeader headerContent ={<section className="container hero row">
            <div className="content">
                <h1>Welcome !!! Security Manager</h1>
            </div>
        </section>}/>
    <Tabs activeKey={activeTab} onChange={handleTabChange}>
      <TabPane tab="Security Management" key="1">
        <SecurityPersonnel />
      </TabPane>
      <TabPane tab="Visitors List" key="2">
        <div>
           <ResidentsAndVisitorsTable/>
        </div>
      </TabPane>
    </Tabs>
    </>
  );
};

export default SecurityManager;
