import React, { useState, useEffect } from 'react';
import { Select, Button, Table, message } from 'antd';

const { Option } = Select;

const ResidentsAccess = () => {
  const [residents, setResidents] = useState([]);
  const [residentId, setResidentId] = useState(null);
  const [accessList, setAccessList] = useState([]);
  const user = JSON.parse(sessionStorage.getItem('user'))
  const poolManagerId = user.id
  // Load the list of residents and existing access records on component mount
  useEffect(() => {
    fetch('https://mxk0258.uta.cloud/api/residents')
      .then(response => response.json())
      .then(data => setResidents(data))
      .catch(error => console.log(error));

    fetch(`https://mxk0258.uta.cloud/api/residents-access/${poolManagerId}`)
      .then(response => response.json())
      .then(data => setAccessList(data))
      .catch(error => console.log(error));
  }, []);

  // Handler for submitting the form to create a new resident access record
  const handleCreateAccess = () => {
    if(!accessList.some(access => access.resident_id === residentId))
    {

    
    fetch('https://mxk0258.uta.cloud/api/residents-access', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        pool_manager_id: poolManagerId,
        resident_id: residentId
      })
    })
      .then(response => response.json())
      .then(data => {
        // setAccessList([...accessList, data]);
        fetch('https://mxk0258.uta.cloud/api/residents')
      .then(response => response.json())
      .then(data => setResidents(data))
      .catch(error => console.log(error));

    fetch(`https://mxk0258.uta.cloud/api/residents-access/${poolManagerId}`)
      .then(response => response.json())
      .then(data => setAccessList(data))
      .catch(error => console.log(error));
        setResidentId(null);
        message.success('Resident access created successfully!');
      })
      .catch(error => console.log(error));
    }else{
      message.error('ID already have access ')
    }
  };

  // Handler for deleting an existing resident access record
  const handleDeleteAccess = id => {
    fetch(`https://mxk0258.uta.cloud/api/residents-access/${id}/${poolManagerId}`, {
      method: 'DELETE'
    })
      .then(response => response.json())
      .then(() => {
        setAccessList(accessList.filter(access => access.id !== id));
        message.success('Resident access deleted successfully!');
      })
      .catch(error => console.log(error));
  };

  const columns = [
    {
      title: 'Resident',
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Button type="danger" onClick={() => handleDeleteAccess(record.id)}>Delete</Button>
      )
    }
  ];
  return (
    <div>
      <h1>Resident Access</h1>
      <>
      <Select value={residentId} onChange={value => setResidentId(value)} style={{ width: 200 }}>
        {residents.map(resident => (
          <Option key={resident.id} value={resident.id}>{resident.username}</Option>
        ))}
      </Select>
      <Button type="primary" onClick={handleCreateAccess}>Create Access</Button>
      <Table dataSource={accessList} columns={columns} rowKey="id" />
      </>
    </div>
  );
};

export default ResidentsAccess;
