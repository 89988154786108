import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { VictoryBar, VictoryChart, VictoryAxis } from 'victory';

const BarChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get('https://mxk0258.uta.cloud/api/data')
      .then(response => {
        // Extract the data you need for the bar chart
        const chartData = response.data.events.map(event => ({
          name: event.name,
          attendees: event.memberships.length
        }));
        setData(chartData);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <VictoryChart>
      <VictoryAxis
        dependentAxis
        tickFormat={tick => tick}
      />
      <VictoryAxis />
      <VictoryBar
        data={data}
        x="name"
        y="attendees"
      />
    </VictoryChart>
  );
};

export default BarChart;
