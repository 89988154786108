import { Layout, Calendar } from 'antd';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const { Header, Content } = Layout;

const SimpleMap = () => {
  const position = [51.505, -0.09]; // default position

  return (
    <Layout style={{ minHeight: '50vh' }}>
      {/* <Header style={{ textDecorationColor: 'white' }}>MAP </Header> */}
      <Content style={{ padding: '0 50px' }}>
        <div className="site-layout-content">
          <MapContainer center={position} zoom={13} style={{ height: '500px', width: '50%' }}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={position}>
              <Popup>
                A pretty CSS3 popup.
              </Popup>
            </Marker>
          </MapContainer>
          <p>Use this map to explore our complex!</p>
          <Calendar />
        </div>
      </Content>
    </Layout>
  );
};

export default SimpleMap;
