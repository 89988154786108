import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, DatePicker, message } from 'antd';
import axios from '../axiosConfig';

const Advertisement = () => {
  const userItem = JSON.parse(sessionStorage.getItem('user'));
  const poolManagerId = userItem.id;
  const [advertisements, setAdvertisements] = useState([]);
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    getAdvertisements();
  }, []);

  const getAdvertisements = async () => {
    try {
      const response = await axios.get(`/api/pool-managers/${poolManagerId}/advertisements`);
      setAdvertisements(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Event Name',
      dataIndex: 'event_name',
      key: 'event_name',
    },
    {
      title: 'Event Description',
      dataIndex: 'event_description',
      key: 'event_description',
    },
    {
      title: 'Advertised At',
      dataIndex: 'advertised_at',
      key: 'advertised_at',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button danger onClick={() => deleteAdvertisement(record.id)}>
          Delete
        </Button>
      ),
    },
  ];

  const createAdvertisement = async (values) => {
    try {
      await axios.post(`/api/pool-managers/${poolManagerId}/advertisements`, {
        event_name: values.event_name,
        event_description: values.event_description,
        advertised_at: values.advertised_at.format('YYYY-MM-DD HH:mm:ss'),
      });
      setModalVisible(false);
      message.success('Advertisement created successfully');
      getAdvertisements();
    } catch (error) {
      console.log(error);
      message.error('Failed to create advertisement');
    }
  };

  const deleteAdvertisement = async (id) => {
    try {
      await axios.delete(`/api/pool-managers/${poolManagerId}/advertisements/${id}`);
      message.success('Advertisement deleted successfully');
      getAdvertisements();
    } catch (error) {
      console.log(error);
      message.error('Failed to delete advertisement');
    }
  };

  return (
    <div>
      <Button type="primary" onClick={() => setModalVisible(true)}>
        Create Advertisement
     
        </Button>
  <Table dataSource={advertisements} columns={columns} rowKey="id" />
  <Modal
    visible={modalVisible}
    title="Create Advertisement"
    okText="Create"
    cancelText="Cancel"
    onCancel={() => setModalVisible(false)}
    onOk={() => {
      form.validateFields().then((values) => {
        form.resetFields();
        createAdvertisement(values);
      });
    }}
  >
    <Form form={form}>
      <Form.Item
        name="event_name"
        label="Event Name"
        rules={[
          {
            required: true,
            message: 'Please input the event name',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="event_description"
        label="Event Description"
        rules={[
          {
            required: true,
            message: 'Please input the event description',
          },
        ]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        name="advertised_at"
        label="Advertised At"
        rules={[
          {
            required: true,
            message: 'Please select the advertised at date and time',
          },
        ]}
      >
        <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
      </Form.Item>
    </Form>
  </Modal>
</div>
);
};

export default Advertisement;